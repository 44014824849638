import { formatDate } from '@/utils/auth/common'
import BaseSelect from '@/components/common/base-select/base-select.vue'

// 查询form
export const dataForm = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    },
    {
      label: '放款日',
      prop: 'loanDate',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions
      },
      tag: 'el-date-picker'
    },
    {
      label: '起息日',
      prop: 'startDate',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions

      },
      tag: 'el-date-picker',
      isHidden: content.fold
    },
    {
      label: '到期日',
      prop: 'endDate',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions

      },
      tag: 'el-date-picker',
      isHidden: content.fold
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '120px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      'show-overflow-tooltip': true

    },
    // {
    //   label: '主借人',
    //   prop: 'mainBorrower',
    //   align: 'left',
    //   'show-overflow-tooltip': true

    // },
    {
      label: '资金方',
      prop: 'capitalSideName',
      align: 'left'
    },
    // {
    //   label: '付款单编码',
    //   prop: 'easNo',
    //   align: 'left'
    // },
    {
      label: '放款金额(元)',
      prop: 'loanAmount',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.loanAmount ? row.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '放款日',
      prop: 'loanDate',
      formatter: row => {
        return formatDate(row.loanDate, 'YY-MM-DD')
      }
    },
    {
      label: '起息日',
      prop: 'startDate',
      formatter: row => {
        return formatDate(row.startDate, 'YY-MM-DD')
      }
    },
    {
      label: '到期日',
      prop: 'endDate',
      formatter: row => {
        return formatDate(row.endDate, 'YY-MM-DD')
      }
    },
    {
      label: '利率（%）',
      prop: 'interestRate'
    },
    {
      label: '服务费利率（%）',
      prop: 'serviceRate'
    },
    {
      label: '登记状态',
      prop: 'registStatus'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
