<template>
  <!-- 附件上传 -->
  <div class="accessory">
    <el-input v-bind="$attrs" class="el_input" :disabled="true"/>
    <base-button :http-request="UploadFile" :btnType="'upload'" label="" class="el_upload" icon='iconfont iconshangchuan'/>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi } from '@/api/fileApi'
export default {
  components: { BaseButton },
  methods: {
    UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.success) {
          this.$emit('uploadFile', res.data)
          this.success('文件上传成功')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.accessory {
  display: flex;
  .el_input {
    flex: 1;
  }
  .el_upload {
    margin-left: 10px;
    width: 32px;
    height: 32px;
    border-radius:50% ;
    /deep/ .el-upload{
      width: 100%;
      button{
        display: block;
        margin: 0;
        padding: 0;
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius:50% ;
        text-align: center;
        .iconfont{
          margin:0 ;
        }
        span{
          display: none;
        }
      }
    }
  }
}
</style>
