// 放款登记
<template>
  <div class="lending-registration">
    <!-- 查询条件 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
      <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"/>
    </div>
    </base-form>
    <!-- 表格数据 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      :queryParas="queryParas"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :loadCount="loadCount"
      :webPage="false">
      <template slot="registStatus" slot-scope="scope">
        <span v-if="scope.row.registStatus === 0|| !scope.row.registStatus">未登记</span>
        <span v-else-if="scope.row.registStatus === 1">已登记</span>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button v-if="scope.row.registStatus === 1 " @click="showDetails(scope.row)" content="查看" icon="iconfont iconchakan" />
        <icon-button v-if="scope.row.registStatus !== 1" @click="showRegister(scope.row)" content="登记" icon="iconfont icondengji"/>
      </template>
    </base-table>
    <!-- 修改放款登记 -->
    <add-register ref="dataInfo" :visible.sync="visible" @updateData="handleFilter" :capitalSideName='capitalSideName' :gysId="rowGysId" :keyId="keyId" :businessId='businessId'/>
    <!-- 详情弹窗  -->
    <dit-details :visible.sync="detailsVisible" :keyId="keyId" :keyIds="keyIds" :businessId='businessId'/>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import AddRegister from './components/add-register.vue'
import { loanplanAPi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import IconButton from '@/components/common/button/icon-button/icon-button'
import DitDetails from './components/dit-details.vue'
export default {
  name: 'lendingRegistration',
  components: { BaseForm, BaseTable, AddRegister, IconButton, DitDetails, BaseButton },
  data () {
    return {
      capitalSideName: '',
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [],
      visible: false,
      detailsVisible: false,
      loadCount: 0,
      keyId: '',
      keyIds: '',
      businessId: '',
      gysData: [],
      rowGysId: ''
    }
  },
  computed: {
    api () {
      return loanplanAPi
    },
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    }
  },
  watch: {
    'queryParas.loanDate': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.loanDateBegin = val[0]
          this.queryParas.loanDateEnd = val[1]
        } else {
          this.queryParas.loanDateBegin = ''
          this.queryParas.loanDateEnd = ''
        }
      },
      deep: true
    },
    'queryParas.startDate': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.startDateBegin = val[0]
          this.queryParas.startDateEnd = val[1]
        } else {
          this.queryParas.startDateBegin = ''
          this.queryParas.startDateEnd = ''
        }
      },
      deep: true
    },
    'queryParas.endDate': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.endDateBegin = val[0]
          this.queryParas.endDateEnd = val[1]
        } else {
          this.queryParas.endDateBegin = ''
          this.queryParas.endDateEnd = ''
        }
      },
      deep: true
    }
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  methods: {
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 显示登记弹窗
    showRegister (data) {
      this.visible = true
      this.keyId = data.finPlanKeyId
      this.businessId = data.businessId
      this.capitalSideName = data.capitalSideName
      this.rowGysId = data.enterpriseId
    },
    // 详情弹窗
    showDetails (data) {
      this.detailsVisible = true
      this.keyId = data.finPlanKeyId
      this.businessId = data.businessId
      this.keyIds = data.keyId
    }
  }
}
</script>
<style lang="scss" scoped>
.lending-registration {
  height: 100%;
}
</style>
