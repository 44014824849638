import accessory from '../components/accessory.vue'
import amountinput from '@/components/input/amount-input/amount-input'
import SelectAccount from '@/pages/business/financing-decision-manage/components/select-account.vue'

// 登记form
export const lendingForm = (content) => {
  // console.log(content.formData, content.capitalSideName, 'n是真的牛逼')
  // 放宽日
  const loanOptions = {
    disabledDate (time) {
      if (content.formData.startDate) {
        return time.getTime() > new Date(content.formData.startDate).getTime()
      } else if (content.formData.endDate) {
        return time.getTime() > new Date(content.formData.endDate).getTime()
      } else {
        return time.getTime() <= new Date('1990-01-01')
      }
    }
  }
  // 起息日
  const startOptions = {
    disabledDate (time) {
      if (content.formData.loanDate && content.formData.endDate) {
        return time.getTime() < new Date(content.formData.loanDate).getTime() - 24 * 60 * 60 * 1000 || time.getTime() > new Date(content.formData.endDate).getTime()
      } else if (content.formData.loanDate) {
        return time.getTime() < new Date(content.formData.loanDate).getTime() - 24 * 60 * 60 * 1000
      } else if (content.formData.endDate) {
        return time.getTime() > new Date(content.formData.endDate).getTime()
      } else {
        return time.getTime() <= new Date('1990-01-01')
      }
    }
  }
  // 到期日
  const endOptions = {
    disabledDate (time) {
      if (content.formData.startDate) {
        return time.getTime() < new Date(content.formData.startDate).getTime()
      } else if (content.formData.loanDate) {
        return time.getTime() < new Date(content.formData.loanDate).getTime()
      } else {
        return time.getTime() <= new Date('1990-01-01')
      }
    }
  }
  // 账户数位限制
  // var validAccount = (rule, value, callback) => {
  //   if (value === '') {
  //     callback(new Error('请输入账户'))
  //   } else {
  //     if (!(/^[0-9]{15,19}$/).test(value)) {
  //       callback(new Error('请输入30位的账户'))
  //     }
  //     callback()
  //   }
  // }
  // 不能输入0
  var validLoanAmount = (rule, value, callback) => {
    if (!value && value !== 0) {
      callback(new Error('请输入放款金额'))
    } else if (value === '0') {
      callback(new Error('放款金额不能为0'))
    } else {
      callback()
    }
  }
  const span = 8
  return [
    {
      label: '账户名：',
      prop: 'accountName',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        gysId: content.gysId,
        rowData: content.accountData
      },
      tag: SelectAccount,
      on: {
        selectAccount (data) {
          content.selectAccount(data)
        },
        clearFormValidate () {
          content.$nextTick(() => {
            content.$refs.formData && content.$refs.formData.clearValidate()
          })
        }
      },
      span,
      rules: [{ required: true, message: '请选择账户名', trigger: ['blur', 'change'] }]
    },
    {
      label: '账号：',
      prop: 'account',
      rules: [{ required: true, message: '请输入100位内账户名', trigger: ['blur', 'change'] }],
      span,
      attrs: {
        disabled: true,
        placeholder: '请输入',
        'max-length': 100
      }
    },
    {
      label: '开户行：',
      prop: 'bankName',
      span,
      attrs: {
        disabled: true,
        placeholder: '请输入'
      },
      rules: [{ required: true, message: '请输入开户行', trigger: ['blur', 'change'] }]
    },
    {
      label: '放款金额（元）：',
      prop: 'loanAmount',
      tag: amountinput,
      attrs: {
        type: 'Y',
        placeholder: '请输入'
      },
      span,
      rules: [{ required: true, validator: validLoanAmount, trigger: ['blur', 'change'] }],
      on: {
        change: (data) => {
          content.changeLoanAmount(data)
        }
      }
    },
    {
      label: '利率（%）：',
      prop: 'finFinanceRate',
      tag: amountinput,
      span,
      attrs: {
        type: 'BL',
        placeholder: '请输入',
        disabled: true
      }

    },
    {
      label: '服务费率（%）：',
      prop: 'finServiceRate',
      tag: amountinput,
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true,
        type: 'BL'
      }
    },
    {
      label: '资金方：',
      prop: 'finCapitalSideName',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },

    {
      label: '放款日：',
      prop: 'loanDate',
      attrs: {
        placeholder: '请选择',
        type: 'date',
        'value-format': 'yyyy-MM-dd',
        pickerOptions: loanOptions
      },
      span,
      tag: 'el-date-picker',
      rules: [{ required: true, message: '请选择放款日', trigger: ['blur'] }]
    },
    {
      label: '起息日：',
      prop: 'startDate',
      attrs: {
        placeholder: '请选择',
        type: 'date',
        'value-format': 'yyyy-MM-dd',
        pickerOptions: startOptions
      },
      span,
      tag: 'el-date-picker',
      rules: [{ required: true, message: '请选择起息日', trigger: ['blur'] }]
    },
    {
      label: '到期日：',
      prop: 'endDate',
      attrs: {
        placeholder: '请选择',
        type: 'date',
        'value-format': 'yyyy-MM-dd',
        pickerOptions: endOptions
      },
      span,
      tag: 'el-date-picker',
      rules: [{ required: true, message: '请选择到期日', trigger: ['blur'] }]
    },

    // 暂时不上，电子章二期内容  建行才有
    {
      label: '贷款合同编号：',
      prop: 'loanContractNo',
      span,
      rules: [{ required: true, message: '请输入贷款合同编号', trigger: ['blur'] }],
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.capitalSideName !== '建设银行'
    },
    {
      label: '其他附件：',
      prop: 'loanFileFileName', // loanFileFileName
      attrs: {
        placeholder: '请选择'
      },
      on: {
        uploadFile: (data) => {
          content.uploadFile(data)
        }
      },
      span,
      tag: accessory
    },
    {
      label: '备注：',
      prop: 'memo',
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxLength: '500',
        rows: 4
      }
    }
  ]
}
