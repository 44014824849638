<template>
  <!-- 详情弹窗 -->
  <base-dialog
    :visible.sync="visibleState"
    width="1200px"
    title="放款登记详情"
    :showFooter="false"
  >
    <!-- 融资决议下载 -->
    <div class="topContent">
      <base-button
        label="融资决议下载"
        icon="iconfont iconxiazai"
        @click="downloadDecision(formData.finFileListInfoVO)"
      />
    </div>
    <!-- 详情列表 -->
    <form-info :count="count" :componentList="dataTable" :model="formData" />
    <template slot="footer">
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import { dataTable } from '../utils/dit-details-config'
import { loanplanAPi } from '@/api/businessApi'
import { formatDate } from '@/utils/auth/common.js'
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
export default {
  data () {
    return {
      formData: {},
      count: 0,
      showInput: true
    }
  },
  components: { baseDialog, BaseButton, FormInfo },
  props: {
    visible: Boolean,
    keyId: String,
    keyIds: String,
    businessId: String
  },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    dataTable () {
      return dataTable(this)
    },
    api () {
      return loanplanAPi
    }
  },
  methods: {
    // 时间处理
    dealTime (data) {
      data = data ? formatDate(data, 'YY-MM-DD') : ''
      return data
    },
    // 获取详情数据
    detail () {
      this.formData = {}
      this.api
        .detail({ finPlanKeyId: this.keyId, businessId: this.businessId, keyId: this.keyIds })
        .then((res) => {
          if (res.success && res.data) {
            this.formData = {
              ...res.data,
              loanFileFileName: res.data.loanFileFileName
                ? res.data.loanFileFileName
                : '',
              loanDate: this.dealTime(res.data.loanDate),
              startDate: this.dealTime(res.data.startDate),
              endDate: this.dealTime(res.data.endDate)
            }
            // 详情内容 判断是否显示项目编号
            res.data.capitalSideName === '建设银行' ? this.showInput = false : this.showInput = true

            this.count++
          }
        })
    },
    // 融资决议下载
    downloadDecision (data) {
      if (data) {
        const params = { keyId: data.fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.warning('抱歉暂无数据')
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
      }
    }
  }
}
</script>
<style lang="scss" scope>
.topContent {
  text-align: right;
  margin-bottom: 20px;
}
</style>
