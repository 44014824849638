// 登记弹窗
<template>
  <base-dialog
    :visible.sync="visibleState"
    width="1200px"
    :showFooter="false"
    title="放款登记"
  >
    <div class="add-andEdit">
      <!-- 文件下载 -->
      <div class="top-content">
        <base-button
          label="融资决议下载"
          icon="iconfont iconxiazai"
          @click="downloadDecision(formData.finFileListInfoVO)"
        />
      </div>
      <!-- 登记表单 -->
      <base-form
        :componentList="lendingForm"
        :formAttrs="{
          model: formData,
          labelWidth: '140px',
        }"
        :showBtns="false"
        class="formData"
        ref="formData"
      />
    </div>
    <template slot="footer">
      <base-button label="提 交" @click="submit" />
      <base-button label="关 闭" type="default" @click="visibleState = false" />
    </template>
  </base-dialog>
</template>

<script>
import baseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { lendingForm } from '../utils/add-config'
import { loanplanAPi } from '@/api/businessApi'
import { formatDate } from '@/utils/auth/common.js'
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
export default {
  data () {
    return {
      formData: {
        finPlanKeyId: '',
        loanFileFileId: '',
        loanFileFileName: ''
      },
      // 选择的账户信息
      accountData: {}
    }
  },
  props: {
    capitalSideName: String,
    visible: Boolean,
    keyId: String,
    businessId: String,
    gysId: String
  },
  components: { baseForm, BaseButton, BaseDialog },
  computed: {
    lendingForm () {
      return lendingForm(this)
    },
    api () {
      return loanplanAPi
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
        this.$nextTick(() => {
          this.$refs.formData.resetFields()
        })
      }
    }
  },
  methods: {
    // 时间处理
    dealTime (data) {
      data = data ? formatDate(data, 'YY-MM-DD') : ''
      return data
    },
    // 登记数据
    detail () {
      this.formData = {}
      this.api
        .detail({ finPlanKeyId: this.keyId, businessId: this.businessId })
        .then((res) => {
          if (res.success && res.data) {
            this.formData = {
              ...res.data,
              loanFileFileName: res.data.loanFileFileName
                ? res.data.loanFileFileName
                : '',
              loanDate: this.dealTime(res.data.loanDate),
              startDate: this.dealTime(res.data.startDate),
              endDate: this.dealTime(res.data.endDate)
            }
            this.accountData = {
              account: res.data.accountName,
              accountNo: res.data.account,
              bankName: res.data.bankName
            }
            this.$nextTick(() => {
              this.$refs.formData.clearValidate()
            })
          }
        })
    },
    // 上传附件
    uploadFile (data) {
      this.formData.loanFileFileId = data.keyId
      this.formData.loanFileFileName = data.fileName
    },
    // 融资决议下载
    downloadDecision (data) {
      if (data) {
        downAPi
          .download({ keyId: data.fileId })
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      }
    },
    // 登记提交
    submit () {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          // 调用额度校验接口
          const params = {
            businessId: this.businessId,
            financeAmount: this.formData.loanAmount
          }
          this.api.getValidInfo(params).then(res => {
            if (res.data.status) {
              this.saveData()
            } else {
              this.$confirm(res.data.errorMsg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.saveData()
              }).catch(() => {

              })
            }
          })
        }
      })
    },
    // 提交数据
    saveData () {
      this.api.info(this.formData).then((res) => {
        if (res.success) {
          this.success('登记成功')
          this.visibleState = false
          this.$emit('updateData')
        }
      })
    },
    // 放款金额弹窗提示
    changeLoanAmount (data) {
      if (data && data * 1 !== this.formData.financeAmount && data !== '0') {
        this.$stateConfirm({
          title: '提示',
          message: '放款金额不等于计划融资金额，是否继续登记？',
          show: true,
          type: 'warning'
        })
          .then(() => {
            // 用户点击确认后执行(不执行操作,继续登记)
          })
          .catch(() => {
            // 取消或关闭
            this.visibleState = false
          })
      }
    },
    // 账户选择
    selectAccount (data) {
      this.$set(this.formData, 'accountName', data.account)
      this.$set(this.formData, 'account', data.accountNo)
      this.$set(this.formData, 'bankName', data.bankName)
      this.accountData = data
    }
  }
}
</script>

<style lang="scss" scoped>
.add-andEdit {
  padding: 20px;
  .top-content {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
