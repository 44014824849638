import { render, staticRenderFns } from "./accessory.vue?vue&type=template&id=465c9112&scoped=true&"
import script from "./accessory.vue?vue&type=script&lang=js&"
export * from "./accessory.vue?vue&type=script&lang=js&"
import style0 from "./accessory.vue?vue&type=style&index=0&id=465c9112&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465c9112",
  null
  
)

export default component.exports