import downLoadBtn from '../components/downLoadBtn.vue'

// 详情form
export const dataTable = (content) => {
  const span = 12
  return [
    {
      label: '账户名',
      prop: 'accountName',
      minWidth: '120px',
      align: 'left',
      span
    },
    {
      label: '账户',
      prop: 'account',
      minWidth: '120px',
      align: 'left',
      'show-overflow-tooltip': true,
      span

    },
    {
      label: '开户行',
      prop: 'bankName',
      minWidth: '120px',
      align: 'left',
      'show-overflow-tooltip': true,
      span

    },
    {
      label: '放款金额(元)',
      prop: 'loanAmount',
      minWidth: '120px',
      align: 'left',
      type: 'Amount',
      span
    },
    {
      label: '利率(%)',
      prop: 'interestRate',
      minWidth: '120px',
      span
    },
    {
      label: '服务费率(%)',
      prop: 'serviceRate',
      minWidth: '120px',
      span
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      minWidth: '120px',
      span
    },
    {
      label: '放款日',
      prop: 'loanDate',
      minWidth: '120px',
      span
    },
    {
      label: '起息日',
      prop: 'startDate',
      minWidth: '150px',
      span
    },
    {
      label: '到期日',
      prop: 'endDate',
      minWidth: '120px',
      span
    },
    {
      label: '贷款合同编号',
      prop: 'loanContractNo',
      span,
      isHidden: content.showInput
    },
    {
      label: '其他附件',
      prop: 'loanFileFileName',
      attrs: {
        icon: 'iconfont iconxiazai',
        tipTxt: '下载授权书',
        fileId: content.formData.loanFileFileId
      },
      tag: downLoadBtn,
      span
    },
    {
      label: '备注',
      prop: 'memo',
      minWidth: '120px',
      span: 24,
      showAll: true
    }
  ]
}
